<template>
  <div class="active-filters">
    <p v-show="showLabel" class="visible-xs-and-tablet active-filters-title w-100 m0 py10">
      {{ $t('Active filters') }}:
    </p>
    <div
      v-for="(filter, filterIndex) in filters"
      :key="filterIndex"
    >
      <template v-if="isFiltersAreArrays()">
        <span
          v-for="(option, index) in filter"
          :key="index"
          :class="{'margin-bottom': stylesSidebarMobile}"
          class="active-label pointer p5 bg-cl-white cl-main mr10 middle-xs brdr-10px hover-shadow-4 mb5 weight-500"
          @click="$emit('change', option)"
        >
          {{ option.label }}
          <i class="icon-zamknij h6 ml10" />
        </span>
      </template>
      <template v-else>
        <span
          @click="$emit('change', filter)"
          class="pointer uppercase active-filter-label"
        >
          <span class="cl-silver">{{ filter.label }}</span>
          <i class="icon-zamknij h6 ml10" />
        </span>
      </template>
    </div>
    <div>
      <span @click="toggleSaleFilter()" v-if="isSaleFilterActive" :class="{'margin-bottom': stylesSidebarMobile}" class="active-label pointer p5 bg-cl-white cl-main mr10 flex middle-xs brdr-10px hover-shadow-4 mb5 weight-500">
        {{ $t('Sale') }}
        <i class="icon-zamknij h6 ml10" />
      </span>
    </div>
    <div>
      <span @click="toggleNewFilter()" v-if="isNewFilterActive" :class="{'margin-bottom': stylesSidebarMobile}" class="active-label pointer p5 bg-cl-white cl-main mr10 flex middle-xs brdr-10px hover-shadow-4 mb5 weight-500">
        {{ $t('New') }}
        <i class="icon-zamknij h6 ml10" />
      </span>
    </div>
    <div>
      <span @click="toggleQueryFilter()" v-if="isQueryFilterActive" :class="{'margin-bottom': stylesSidebarMobile}" class="active-label pointer p5 bg-cl-white cl-main mr10 flex middle-xs brdr-10px hover-shadow-4 mb5 weight-500">
        Szukasz: {{ queryByWord }}
        <i class="icon-zamknij h6 ml10" />
      </span>
    </div>
    <div>
      <span @click="toggleBestselleryFilter()" v-if="isBestselleryFilterActive" :class="{'margin-bottom': stylesSidebarMobile}" class="active-label pointer p5 bg-cl-white cl-main mr10 flex middle-xs brdr-10px hover-shadow-4 mb5 weight-500">
        {{ $t('Bestsellers') }}
        <i class="icon-zamknij h6 ml10" />
      </span>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash-es/pickBy'

export default {
  name: 'ActiveFilters',
  props: {
    filters: {
      type: Object,
      required: true
    },
    showLabel: {
      type: Boolean,
      required: false
    },
    stylesSidebarMobile: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    queryByWord () {
      return this.$route.query && this.$route.query.query ? this.$route.query.query : null
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    isBestselleryFilterActive () {
      return this.$store.state.ui.isBestselleryFilterActive
    }
  },
  methods: {
    toggleQueryFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleQueryFilter', query).then(() => {
        if (!this.isQueryFilterActive) {
          delete query.query
          this.$router.push({ query })
        }
      })
    },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          delete query.sort
          this.$router.push({ query })
        } else {
          query.new = 'true'
          query.sort = 'id:desc'
          this.$router.push({ query })
        }
      })
    },
    toggleBestselleryFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleBestselleryFilter').then(() => {
        if (!this.isBestselleryFilterActive) {
          delete query.bestsellery
          this.$router.push({ query })
        } else {
          query.bestsellery = 'true'
          this.$router.push({ query })
        }
      })
    },
    isFiltersAreArrays () {
      let filterValues = Object.values(this.filters)
      if (Array.isArray(filterValues[0])) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

.margin-bottom {
  margin-bottom: 5px !important;
}
.active-filters-title {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    font-size: 10px;
    font-weight: 600;
  }
}

.active-filters {
  display: flex;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    &::after {
      content: '';
      height: 30px;
      width: 20px;
      position: fixed;
      right: 16.5%;
    }
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin: auto 0;
  }
  .active-label {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      white-space: nowrap;
      border: 1px solid #CFCFCF;
      margin-bottom: 0;
      border-radius: 50px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  > div {
    @media (max-width: 767px) {
      // padding-top: 5px;
    }
    // float: left;
    // margin-bottom: 10px;
  }
}

.inline-block {
  display: inline-block;
}

.lh0 {
  line-height: 0;
}
</style>
