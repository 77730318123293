import { render, staticRenderFns } from "./ActiveFilters.vue?vue&type=template&id=6059f4fa&scoped=true&"
import script from "./ActiveFilters.vue?vue&type=script&lang=js&"
export * from "./ActiveFilters.vue?vue&type=script&lang=js&"
import style0 from "./ActiveFilters.vue?vue&type=style&index=0&id=6059f4fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6059f4fa",
  null
  
)

export default component.exports