<template>
  <div class="start-xs category-filters relative">
    <div class="sidebar" id="sidebar-desktop" :class="[{ 'fixed': !bottom && filtersVisible },{ 'bottom-fixed' : bottom && filtersVisible }]" :style="heightFromTop">
      <div class="filters bg-cl-white brdr-10px pl25 py10">
        <div class="py5">
          <span
            class="filter-label-squere flex middle-xs no-underline mr10 cl-black relative pointer"
            @click="toggleSaleFilter()"
            :class="{ active: isSaleFilterActive }"
          >
            {{ $t('Sale') }}
          </span>
        </div>
        <div class="py5">
          <span
            class="filter-label-squere flex middle-xs no-underline mr10 cl-black relative pointer"
            @click="toggleNewFilter()"
            :class="{ active: isNewFilterActive }"
          >
            {{ $t('Everything new') }}
          </span>
        </div>
        <div class="py5">
          <span
            class="filter-label-squere flex middle-xs no-underline mr10 cl-black relative pointer"
            @click="toggleBestselleryFilter()"
            :class="{ active: isBestselleryFilterActive }"
          >
            {{ $t('Bestsellers') }}
          </span>
        </div>
        <p class="py5 my0 h5 flex pointer cl-black weight-600" @click="toggleCurrent('category')">
          {{ $t('Category') }}
          <i class="fs-medium ml10" :class="!toggleFilters.has('category') ? 'icon-rozwi' : 'icon-rozwi up'" />
        </p>
        <div v-show="toggleFilters.has('category')">
          <div class="mb10" v-if="getCurrentCategory.id !== 2 && getCustomParentCategory">
            <span>{{ $t('Back to') }}:</span>
            <router-link
              class="cl-main pointer"
              :to="localizedRoute({ path: '/' + getCustomParentCategory.url_path, query: $route.query})"
            >
              {{ getCustomParentCategory.name }}
            </router-link>
          </div>
        </div>
        <div
          v-show="toggleFilters.has('category')"
          class="scroll-box mb15"
        >
          <div
            v-for="singleCategory in children"
            :key="singleCategory.slug"
            class="py5"
          >
            <router-link
              class="category-hover no-underline ml10 cl-black"
              :class="{ active: getCurrentCategory.id === singleCategory.id }"
              :to="localizedRoute({path: '/' + singleCategory.url_path, query: $route.query})"
            >
              {{ singleCategory.name }}
            </router-link>
          </div>
        </div>
        <div
          v-for="(filter, filterIndex) in availableFilters"
          :key="filterIndex"
        >
          <p v-if="!hidePriceFilter" class="py5 my0 h5 flex pointer cl-black weight-600" @click="toggleCurrent(filterIndex)">
            {{ $t(filterIndex + '_filter') }}
            <i class="fs-medium ml10" :class="!toggleFilters.has(filterIndex) ? 'icon-rozwi' : 'icon-rozwi up'" />
          </p>
          <div v-if="filterIndex === 'color_aggregated'" class="scroll-box">
            <color-selector
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              code="color"
              v-for="(color, index) in filter"
              :key="index"
              :variant="color"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
          <div v-else-if="filterIndex === 'womens_sizes' || filterIndex === 'kids_sizes' || filterIndex === 'mens_sizes'">
            <size-selector
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              code="size"
              v-for="(size, index) in sortById(filter)"
              :key="index"
              :variant="size"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
          <div v-else-if="!hidePriceFilter" class="flex wrap scroll-box">
            <generic-selector
              v-show="toggleFilters.has(filterIndex)"
              context="category"
              class="block"
              :multiply="filterIndex !== 'price'"
              :code="filterIndex"
              v-for="(option, index) in filter"
              :key="index"
              :variant="option"
              :selected-filters="getCurrentFilters"
              @change="$emit('changeFilter', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'
import { mapGetters } from 'vuex'
import ColorSelector from 'theme/components/core/ColorSelector'
import SizeSelector from 'theme/components/core/SizeSelector'
// import PriceSelector from 'theme/components/core/PriceSelector'
import GenericSelector from 'theme/components/core/GenericSelector'
import pickBy from 'lodash-es/pickBy'
import config from 'config'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import groupBy from 'lodash-es/groupBy'

export default {
  components: {
    ColorSelector,
    SizeSelector,
    // PriceSelector,
    GenericSelector
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    isFiltersToogle: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      globalMapIncludeCategory: '',
      toggleFilters: new Set(['category', 'price', 'publication_form']),
      filtersVisible: false,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      scrollHeight: 355,
      bottom: false,
      heightFromTop: ''
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCustomParentCategory: 'category-next/getCustomParentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    hidePriceFilter () {
      let arr = config.server.categoryIdsToHidePrice
      return arr.includes(this.getCurrentCategory.id)
    },
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    visibleCategories () {
      return this.categoriesMap[config.server.mainCategoryId]
    },
    hasActiveFilters () {
      return this.$store.getters['category-next/hasActiveFilters']
    },
    isQueryNotEmpty () {
      return Object.keys(this.$route.query).length
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    getCurrentFilters () {
      return this.$store.getters['category-next/getCurrentFilters']
    },
    children () {
      if (config.entities.category.categoriesDynamicPrefetch && (this.getCurrentCategory.children_data && this.getCurrentCategory.children_data.length > 0 && this.getCurrentCategory.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.getCurrentCategory.children_data
      } else {
        return this.getMenuCategories.filter(c => {
          return c.parent_id === this.getCurrentCategory.id
        }) // return my child categories
      }
    },
    availableFilters () {
      return pickBy(this.filters, (filter, filterType) => { return (filter.length && !this.$store.getters['category-next/getSystemFilterNames'].includes(filterType)) })
    },
    activeFilters () {
      return this.getActiveCategoryFilters
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isBestselleryFilterActive () {
      return this.$store.state.ui.isBestselleryFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    }
  },
  beforeMount () {
    this.$bus.$on('has-scrolled', this.onHasScrolled)
  },
  mounted () {
    this.toggleFilters = new Set(['category', 'price', 'publication_form'])
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.onHasScrolled)
  },
  watch: {
    $route (to, from) {
      this.$store.dispatch('category-next/fetchMenuCategories', { parent: this.getCurrentCategory.id })
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    onHasScrolled () {
      this.hasScrolledSidebar()
      this.bottom = this.bottomVisibleSidebar()
    },
    getValueforBrand (slug) {
      slug = slug ? slug.toLowerCase() : ''
      if (this.listByCode && this.listByCode['brand'] && slug) {
        let label = this.listByCode['brand'].options.filter((option) => { return option.label.toLowerCase().includes(slug) })
        if (label && label.length) {
          return label[0].value
        } else {
          return false
        }
      }
    },
    resetAllFilters () {
      this.$store.dispatch('category-next/resetSearchFilters')
      this.$store.dispatch('ui/resetSaleFilter')
      this.$store.dispatch('ui/resetNewFilter')
    },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          delete query.sort
          this.$router.push({ query })
        } else {
          query.new = 'true'
          query.sort = 'id:desc'
          this.$router.push({ query })
        }
      })
    },
    toggleBestselleryFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleBestselleryFilter').then(() => {
        if (!this.isBestselleryFilterActive) {
          delete query.bestsellery
          this.$router.push({ query })
        } else {
          query.bestsellery = 'true'
          this.$router.push({ query })
        }
      })
    },
    sortById (filters) {
      return [...filters].sort((a, b) => { return a.id - b.id })
    },
    bottomVisibleSidebar () {
      const element = document.getElementById('sidebar-desktop') ? document.getElementById('sidebar-desktop').scrollHeight : 0
      const categoryDesc = document.getElementById('category-desc') ? document.getElementById('category-desc').scrollHeight + 60 : 0
      // const categoryImage = document.getElementById('category-image') ? document.getElementById('category-image').scrollHeight : 0
      const SAFETY_MARGIN = document.getElementById('main-footer') ? document.getElementById('main-footer').scrollHeight : 0
      const scrollY = window.scrollY
      const visible = window.innerHeight
      const pageHeight = document.documentElement.scrollHeight
      // header height
      const bottomOfPage = scrollY + element + 113 >= pageHeight - SAFETY_MARGIN - categoryDesc
      let heightFromTop1 = pageHeight - SAFETY_MARGIN - categoryDesc - element - 450
      this.heightFromTop = `top: ${heightFromTop1}px;`
      return bottomOfPage || pageHeight < visible
    },
    hasScrolledSidebar () {
      this.scrollTop = window.scrollY
      const sidebar = document.getElementById('sidebar-desktop') ? document.getElementById('sidebar-desktop').scrollHeight : 0
      const listing = document.getElementById('product-listing') ? document.getElementById('product-listing').scrollHeight : 0
      if ((this.scrollTop > this.scrollHeight) && (sidebar < listing)) {
        this.filtersVisible = true
      } else {
        this.filtersVisible = false
      }
      this.lastScrollTop = this.scrollTop
    },
    toggleCurrent (filterIndex) {
      if (filterIndex) {
        let cache = this.toggleFilters
        if (!cache.has(filterIndex)) {
          cache.add(filterIndex)
        } else {
          cache.delete(filterIndex)
        }
        this.toggleFilters = null
        this.toggleFilters = cache
      }
    },
    toogleFilter () {
      this.$emit('toogleUpdate', !this.isFiltersToogle)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.category-hover {
  &:hover {
    color: $main;
  }
}
i.up {
  transform: rotate(180deg);
}
.price-box {
  max-width: 90%;
  margin: 0 auto;
}
.scroll-box {
  overflow-x: hidden;
  max-height: 180px;
  overflow-y: auto;
}
.sidebar {
  &.fixed {
    // transition: all 0.25s;
    position: fixed;
    width: 300px;
    top: 90px !important;
    @media (max-width: 992px) {
      width: 24%;
    }
    @media (max-width: 1565px) {
      width: 19%;
    }
  }
  &.bottom-fixed {
    position: absolute;
    width: 300px;
    top: 1000px;
    @media (max-width: 992px) {
      // width: 24%;
      width: 100%;
    }
    @media (max-width: 1565px) {
      // width: 19%;
      width: 100%;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }

  &__inline-selecors {
    display: flex;
  }
}
.filter-label-squere {
  cursor: pointer;
  position: relative;
  &:hover {
   &::before {
      border: 2px solid $main;
    }
    &:after {
      left: 5px;
      top: 6px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
  &::before {
    content: "";
    position: relative;
    top: 1px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &:after {
    content: "";
    position: absolute;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 6px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
}
.filter-label {
  cursor: pointer;
  position: relative;
  &:hover {
   &::before {
      border: 2px solid $main;
    }
    &:after {
      left: 7px;
      top: 8px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
    }
  }
  &::before {
    content: "";
    position: relative;
    top: 1px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &:after {
    content: "";
    position: absolute;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 8px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
    }
  }
}
</style>
