<template>
  <div id="category" class="category-box bg-cl-main-smoke pt40">
    <div v-show="!isSidebarOpen" class="bg-cl-white visible-xs-and-tablet category-header shadow-1-revert" :class="{ 'fixed' : navVisible }">
      <div class="row middle-xs pb5 w-100">
        <div class="col-xs-12 p10 pb5 flex middle-xs">
          <back-button />
          <h3 class="category-title secondary-font mr5">
            {{ getCurrentCategory.name }}
          </h3>
          <span class="h5">
            ({{ productTotal }})
          </span>
        </div>
        <div class="row m0 w-100 align-items-center">
          <sidebar-mobile class="col-xs-3 relative" :filters="getAvailableFilters" @changeFilter="changeFilter" :value="selectedSort" />
          <active-filters class="col-xs-7 relative" :filters="getCurrentFilters" @change="changeFilter" />
          <custom-columns class="col-xs-2 center-xs" @change-column="columnChange" />
        </div>
      </div>
    </div>
    <landing-category v-if="getCurrentCategory.is_landing && getCurrentCategory.id === 3" />
    <div v-else class="container pb60 top-second-container">
      <div class="col-md-12 hidden-xs-and-tablet">
        <breadcrumbs />
      </div>
      <div class="row my10 ">
        <div class="col-md-12 col-tablet image-box">
          <div class="offer">
            <img
              class="brdr-10px offer-image"
              :alt="getCurrentCategory.name"
              :src="thumbnail"
              data-testid="brandImage"
              width="1549px"
              height="220px"
            >
          </div>
        </div>
      </div>
      <div class="row mb5 hidden-xs-and-tablet">
        <div class="col-md-3 col-lg-2dot4">
          <p class="pointer h5 brdr-10px bg-cl-white weight-600 m0 filter-toggle-button" @click="toggleFilters()">
            <span class="flex center-xs">{{ !isFiltersToogle ? $t('Show filters') : $t('Hide filters') }}</span>
          </p>
        </div>
        <div class="col-md-9 col-lg-9dot6">
          <div class="row bg-cl-white brdr-10px m0">
            <p v-show="!isSpecialCategory && showNotFoundProductsInfo" class="col-sm-2 m0 cl-black flex middle-xs">
              {{ $t('{count} items', { count: productTotal }) }}
            </p>
            <div class="sorting col-sm-2 end-sm align-right ml-auto">
              <sort-by
                :has-label="true"
                @change="changeFilter"
                :value="selectedSort"
              />
            </div>
            <div class="sorting col-sm-1 end-sm align-right">
              <custom-columns @change-column="columnChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2dot4 start-xs category-filters">
          <sidebar v-show="isFiltersToogle" :filters="getAvailableFilters" @changeFilter="changeFilter" @toogleUpdate="onFiltersChange" :is-filters-toogle="isFiltersToogle" />
        </div>
        <div :class="isFiltersToogle ? 'col-md-9 col-lg-9dot6' : 'col-md-12'" class="border-box products-list col-tablet">
          <div class="mt5 active-filters hidden-xs" v-show="isActiveFilters">
            <div class="row mx0" :class="[{ 'fixed-bar' : navVisible },{ 'full-width' : !isFiltersToogle}]">
              <active-filters :filters="getCurrentFilters" @change="changeFilter" />
              <span
                class="h5 pointer bg-cl-white brdr-10px py5 px10 mr10 hover-shadow-4 mb5"
                @click="resetAllFilters"
              >
                {{ $t('Clear filters') }}
                <i class="icon-zamknij h6 ml10" />
              </span>
            </div>
          </div>
          <div v-if="isCategoryEmpty && !isSpecialCategory && showNotFoundProductsInfo" class="hidden-xs">
            <h4 data-testid="noProductsInfo">
              {{ $t('No products found!') }}
            </h4>
            <p>{{ $t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!') }}</p>
          </div>
          <p v-show="getSimpleProducts && getSimpleProductsTotal" :style="getSimpleProducts && getSimpleProductsTotal ? specialBackgroundColor : ''" class="cl-white p10 brdr-10px mt5 mb10 mx8-xs">{{ $t('Oferty podstawowe') }}</p>
          <subscription-listing :products="getSimpleProducts" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="getRenewableProducts && getRenewableProductsTotal" class="cl-white p10 brdr-10px mt5 mb10 mx8-xs" :style="getRenewableProducts && getRenewableProductsTotal ? specialBackgroundColor : ''">{{ $t('Oferty autoodnawialne') }}</p>
          <subscription-listing :products="getRenewableProducts" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="getGiftsProducts && getGiftsProductsTotal" class="cl-white p10 brdr-10px mt5 mb10 mx8-xs" :style="getGiftsProducts && getGiftsProductsTotal ? specialBackgroundColor: ''">{{ $t('Oferty z prezentami') }}</p>
          <subscription-listing :products="getGiftsProducts" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="getSubscriptionProducts && getSubscriptionProducts.length" class="cl-white bg-cl-main p10 brdr-10px mt5 mb10 mx8-xs">{{ $t('Prenumerata') }} <span v-show="isPrenumerationPremiumCategory">{{ $t('Premium') }}</span></p>
          <subscription-listing :products="getSubscriptionProducts" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="getOtherCategoryProducts(2334) && getOtherCategoryProductsTotal(2334)" class="cl-white bg-cl-main p10 brdr-10px mt5 mb10 mx8-xs">{{ $t('Minerały świata Wydania pojedyncze') }}</p>
          <subscription-listing :products="getOtherCategoryProducts(2334)" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="getOtherCategoryProducts(2335) && getOtherCategoryProductsTotal(2335)" class="cl-white bg-cl-main p10 brdr-10px mt5 mb10 mx8-xs">{{ $t('Minerały świata Edycja 2022 Wydania pojedyncze') }}</p>
          <subscription-listing :products="getOtherCategoryProducts(2335)" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" />
          <p v-show="(getCategoryProductsTotal && getSubscriptionProducts && getSubscriptionProducts.length) || (getCategoryProductsTotal && selectedCategoryIds)" class="cl-white bg-cl-main p10 brdr-10px mt5 mb10 mx8-xs">{{ $t('Wydania pojedyncze') }}</p>
          <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabled  && showNotFoundProductsInfo">
            <product-listing :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" :products="getCategoryProducts" />
          </lazy-hydrate>
          <product-listing v-else-if="showNotFoundProductsInfo" :current-category-name="isQueryFilterActive ? 'Search page' : getCurrentCategory.name" :columns="defaultColumn" :filter-toogle="isFiltersToogle" :products="getCategoryProducts" />
          <div v-if="loadingProducts" class="flex column middle-xs">
            <Loader :color="'#1396EC'" />
            <p class="loader-text weight-600 cl-main mt0">
              {{ $t('More products are being loaded') }}
            </p>
          </div>
        </div>
        <div id="category-desc" class="col-sm-12 category-desc weight-400 cl-black lh25">
          <div v-html="getCurrentCategory.description" class="category-inner" :class="{ 'expanded' : expanded }" />
          <span class="show-more" @click="expandDesc" v-if="!expanded && getCurrentCategory.description">{{ $t('Expand') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import Sidebar from '../components/core/blocks/Category/Sidebar.vue'
import ProductListing from '../components/core/ProductListing.vue'
import SubscriptionListing from '../components/core/SubscriptionListing.vue'
import Breadcrumbs from '../components/core/Breadcrumbs.vue'
import SortBy from '../components/core/SortBy.vue'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import config from 'config'
// import Columns from '../components/core/Columns.vue'
import CustomColumns from '../components/core/blocks/Category/CustomColumns.vue'
import { mapGetters, mapState } from 'vuex'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'
import rootStore from '@vue-storefront/core/store';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks'
import { localizedRoute, currentStoreView } from '@vue-storefront/core/lib/multistore'
import { htmlDecode } from '@vue-storefront/core/filters'
import LandingCategory from 'theme/components/core/blocks/Category/LandingCategory.vue'
import Loader from 'theme/components/core/LoadingSpinner.vue'

import { getThumbnailCategoryPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import ActiveFilters from '../components/core/blocks/Category/ActiveFilters.vue'
import BackButton from '../components/core/BackButton'
import i18n from '@vue-storefront/i18n'
import SidebarMobile from '../components/core/blocks/Category/SidebarMobile.vue'

const THEME_PAGE_SIZE = 50

const composeInitialPageState = async (store, route, forceLoad = false) => {
  try {
    const filters = getSearchOptionsFromRouteParams(route.params)
    const cachedCategory = store.getters['category-next/getCategoryFrom'](route.path)
    const currentCategory = cachedCategory && !forceLoad ? cachedCategory : await store.dispatch('category-next/loadCategory', { filters })
    const pageSize = store.getters['url/isBackRoute'] ? store.getters['url/getCurrentRoute'].categoryPageSize : THEME_PAGE_SIZE
    if (currentCategory.id === 3 && currentCategory.is_landing) return

    await store.dispatch('category-next/loadCategoryProducts', { route, category: currentCategory, pageSize })

    await store.dispatch('subscription/loadSpecialProducts', { route, category: currentCategory, type: 'subscriptions' })
    await store.dispatch('subscription/loadSpecialProducts', { route, category: currentCategory, type: 'simple' })
    await store.dispatch('subscription/loadSpecialProducts', { route, category: currentCategory, type: 'gifts' })
    await store.dispatch('subscription/loadSpecialProducts', { route, category: currentCategory, type: 'renewable' })

    await store.dispatch('category-next/loadCategories', {
      filters: {
        id: [2334, 2335]
      }
    })
    const categoryMap = store.getters['category-next/getCategoriesMap'];
    await store.dispatch('subscription/loadSpecialProducts', { route, category: categoryMap[2334], type: 'category' })
    await store.dispatch('subscription/loadSpecialProducts', { route, category: categoryMap[2335], type: 'category' })

    const breadCrumbsLoader = store.dispatch('category-next/loadCategoryBreadcrumbs', { category: currentCategory, currentRouteName: currentCategory.name, omitCurrent: true })

    if (isServer) await breadCrumbsLoader
    catalogHooksExecutors.categoryPageVisited(currentCategory)
  } catch (e) {
    Logger.error('Problem with setting Category initial data!', 'category', e)()
  }
}

export default {
  components: {
    LazyHydrate,
    LandingCategory,
    ProductListing,
    SubscriptionListing,
    Breadcrumbs,
    Sidebar,
    SortBy,
    ActiveFilters,
    CustomColumns,
    BackButton,
    SidebarMobile,
    Loader
  },
  mixins: [onBottomScroll],
  data () {
    return {
      isFiltersToogle: true,
      defaultColumn: 'on',
      navbarHeight: 430,
      navVisible: false,
      loadingProducts: false,
      loading: true,
      expanded: false
    }
  },
  beforeMount () {
    this.$bus.$on('has-scrolled', this.hasScrolled)
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.hasScrolled)
  },
  computed: {
    showNotFoundProductsInfo () {
      return this.getCurrentCategory.id !== 2333
    },
    isSpecialCategory () {
      return config.server.categoryIdsSpecial.includes(this.getCurrentCategory.id) || config.server.categoryIdsSpecial.includes(this.getCurrentCategory.parent_id)
    },
    isPrenumerationPremiumCategory () {
      return config.server.categoryIdsPrenumerationPremium.includes(this.getCurrentCategory.id) || config.server.categoryIdsPrenumerationPremium.includes(this.getCurrentCategory.parent_id)
    },
    specialBackgroundColor () {
      if (this.getCurrentCategory.id === 1230 || this.getCurrentCategory.parent_id === 1230) return 'background-color: #e2809f'
      if (this.getCurrentCategory.id === 1226 || this.getCurrentCategory.parent_id === 1226) return 'background-color: #e6a0b5'
      if (this.getCurrentCategory.id === 1237 || this.getCurrentCategory.parent_id === 1237) return 'background-color: #f0af00'
      if (this.getCurrentCategory.id === 1231 || this.getCurrentCategory.parent_id === 1231) return 'background-color: #c2aa5b'
      if (this.getCurrentCategory.id === 1568 || this.getCurrentCategory.parent_id === 1568) return 'background-color: #eecebf'
      if (this.getCurrentCategory.id === 1236 || this.getCurrentCategory.parent_id === 1236) return 'background-color: #1d9940'
      if (this.getCurrentCategory.id === 1238 || this.getCurrentCategory.parent_id === 1238) return 'background-color: #62ae32'
      if (this.getCurrentCategory.id === 1239 || this.getCurrentCategory.parent_id === 1239) return 'background-color: #232148'
      if (this.getCurrentCategory.id === 1240 || this.getCurrentCategory.parent_id === 1240) return 'background-color: #ffcf00'
      if (this.getCurrentCategory.id === 1241 || this.getCurrentCategory.parent_id === 1241) return 'background-color: #007722'
      if (this.getCurrentCategory.id === 1527 || this.getCurrentCategory.parent_id === 1527) return 'background-color: #ea1f25'
      if (this.getCurrentCategory.id === 1243 || this.getCurrentCategory.parent_id === 1243) return 'background-color: #49904b'
      if (this.getCurrentCategory.id === 1560 || this.getCurrentCategory.parent_id === 1560) return 'background-color: #f59991'
      if (this.getCurrentCategory.id === 1244 || this.getCurrentCategory.parent_id === 1244) return 'background-color: #0a307e'
      return ''
    },
    selectedCategoryIds () {
      const arr = [958, 962, 1212, 1552]
      return arr.includes(this.getCurrentCategory.id)
    },
    ...mapState({
      isSidebarOpen: state => state.ui.sidebar
    }),
    selectedSort () {
      return this.getCurrentSearchQuery.sort
    },
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    isActiveFilters () {
      return Object.keys(this.getCurrentFilters).length ||
        this.isSaleFilterActive ||
        this.isNewFilterActive ||
        this.isBestselleryFilterActive ||
        this.isQueryFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isBestselleryFilterActive () {
      return this.$store.state.ui.isBestselleryFilterActive
    },
    ...mapGetters({
      getSubscriptionProducts: 'subscription/getSubscriptionProducts',
      getSubscriptionProductsTotal: 'subscription/getSubscriptionProductsTotal',
      getSimpleProducts: 'subscription/getSimpleProducts',
      getSimpleProductsTotal: 'subscription/getSimpleProductsTotal',
      getGiftsProducts: 'subscription/getGiftsProducts',
      getGiftsProductsTotal: 'subscription/getGiftsProductsTotal',
      getRenewableProducts: 'subscription/getRenewableProducts',
      getRenewableProductsTotal: 'subscription/getRenewableProductsTotal',
      getOtherCategoryProducts: 'subscription/getOtherCategoryProducts',
      getOtherCategoryProductsTotal: 'subscription/getOtherCategoryProductsTotal',
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-next/getAvailableFilters',
      hasActiveFilters: 'category-next/hasActiveFilters',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes'
    }),
    productTotal () {
      return this.getCategoryProductsTotal + this.getSubscriptionProductsTotal
    },
    getCurrentFilters () {
      return this.$store.getters['category-next/getCurrentFilters']
    },
    isQueryNotEmpty () {
      return Object.keys(this.$route.query).length
    },
    thumbnail () {
      return this.getThumbnail(this.getCurrentCategory.image, 1549, 220, 'category')
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products')
    },
    isCategoryEmpty () {
      return this.productTotal === 0
    }
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    if (context) context.output.cacheTags.add('category')
    await composeInitialPageState(store, route)
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next() // SSR no need to invoke SW caching here
    else if (!from.name) { // SSR but client side invocation, we need to cache products and invoke requests from asyncData for offline support
      next(async vm => {
        vm.loading = true
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
        await composeInitialPageState(vm.$store, to, true)
        await vm.$store.dispatch('category-next/cacheProducts', { route: to }) // await here is because we must wait for the hydration
        vm.loading = false
      })
    } else { // Pure CSR, with no initial category state
      next(async vm => {
        vm.loading = true
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
        vm.$store.dispatch('category-next/cacheProducts', { route: to })
        vm.loading = false
      })
    }
  },
  methods: {
    expandDesc () {
      this.expanded = true
    },
    resetAllFilters () {
      this.$store.dispatch('ui/resetSaleFilter')
      this.$store.dispatch('ui/resetNewFilter')
      this.$store.dispatch('ui/resetQueryFilter')
      this.$store.dispatch('category-next/resetSearchFilters')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (this.scrollTop > this.lastScrollTop && this.scrollTop > this.navbarHeight) {
        this.navVisible = true
      } else {
        this.navVisible = false
      }
      this.lastScrollTop = this.scrollTop
    },
    toggleFilters () {
      this.isFiltersToogle = !this.isFiltersToogle
    },
    onFiltersChange (isFiltersToogle) {
      this.isFiltersToogle = isFiltersToogle
    },
    async changeFilter (filterVariant) {
      this.$store.dispatch('category-next/switchSearchFilters', [filterVariant])
    },
    columnChange (column) {
      this.defaultColumn = column
    },
    async onBottomScroll () {
      if (this.loadingProducts) return
      this.loadingProducts = true
      try {
        await this.$store.dispatch('category-next/loadMoreCategoryProducts')
      } catch (e) {
        Logger.error('Problem with fetching more products', 'category', e)()
      } finally {
        this.loadingProducts = false
      }
    }
  },
  metaInfo () {
    const storeView = currentStoreView()
    const { name, slug } = this.getCurrentCategory
    return {
      titleTemplate: this.getCurrentCategory.meta_title ? '%s' : '%s - Kultowy.pl',
      link: [
        Object.keys(this.$route.params).length > 1 ? {
          rel: 'canonical',
          href: config.server.hostName + localizedRoute('/' + this.getCurrentCategory.url_path)
        } : ''
      ],
      title: this.getCurrentCategory.meta_title ? htmlDecode(this.getCurrentCategory.meta_title) : this.getCurrentCategory.name,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.getCurrentCategory.meta_description ? htmlDecode(this.getCurrentCategory.meta_description) : htmlDecode(this.getCurrentCategory.description).replace(/<[^>]+>/g, ' ').replace(/\s{2,10}/g, ' ')
        },
        {
          property: 'og:type',
          content: 'object'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.getCurrentCategory.meta_title ? htmlDecode(this.getCurrentCategory.meta_title) : htmlDecode(this.getCurrentCategory.name)
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.getCurrentCategory.meta_description ? htmlDecode(this.getCurrentCategory.meta_description) : htmlDecode(this.getCurrentCategory.description).replace(/<[^>]+>/g, ' ').replace(/\s{2,10}/g, ' ')
        },
        {
          vmid: this.getCurrentCategory.url_path ? 'og:url' : '',
          property: this.getCurrentCategory.url_path ? 'og:url' : '',
          content: this.getCurrentCategory.url_path ? htmlDecode(config.server.hostName + '/' + this.getCurrentCategory.url_path) : ''
        },
        {
          property: 'og:image',
          content: config.server.hostName + '/assets/kultowyFacebook.jpg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main: color(main-gray);
$main: color(main);
$white: color(white);

.image-box {
  padding-top: calc(100% * 1/7.1);
  position: relative;
  .offer {
    position: absolute;
    border-radius: 10px;
    background-color: #e6e6e6;
    height: 100%;
    top: 0;
    left: 8px;
    right: 8px;
  }
}

  .loader-text {
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .col-tablet {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  .category-box {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      padding-top: 50px;
      .top-second-container {
        margin-bottom: 0;
        .image-box {
          margin-top: 40px;
        }
      }
    }
  }
  .category-header {
    &.fixed {
      top: -39px;
    }
    .row {
      align-items: stretch;
    }
    transition: all 0.3s;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .active-filters {
    top: 0;
    transition: all 0.3s;
    .fixed-bar {
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        display: none;
      }
      &.full-width {
        left: 8px;
      }
      transition: all 0.3s;
      position: fixed;
      top: 100px;
      z-index: 2;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      position: fixed;
      top: 70px;
      background-color: $color-main;
      z-index: 2;
      right: 8px;
      left: 21%;
      border-radius: 5px;
    }
  }
  .offer {
    background-position: center right;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 0.3s all;
    max-height: 250px;
    .offer-image {
      width: 100%;
      height: auto;
    }
  }
  .filter-toggle-button {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    &__filter {
      min-width: 100px;
    }
  }
  .mobile-sorting {
    display: none;
  }
  .sorting {
    label {
      margin-right: 10px;
    }
  }
  .category-title {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      display: inline;
      margin: 0 5px 0 0;
      font-size: 18px;
    }
    @media (max-width: 767px) {
      display: inline;
      margin: 0 5px 0 0;
      font-size: 12px;
      line-height: 12px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    .products-list {
      width: 100%;
      max-width: none;
    }
    .sorting {
      display: none;
    }

    .mobile-sorting {
      display: block;
    }

    .category-filters {
      display: none;
    }
  }

  .close-container {
    left: 0;
  }

  .close {
    margin-left: auto;
  }
  .category-inner{
    max-height: 7em;
    overflow: hidden
  }
  .expanded{
    max-height: initial !important
  }
  span.show-more {
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 15px;
  }
  .mx8-xs {
    @media (max-width: 767px) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
</style>
