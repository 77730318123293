<template>
  <div class="columns">
    <div v-for="(option, index) in productsColumns" :key="index" class="">
      <label class="radioStyled flex" v-show="option !== column">
        <i class="py15 px10 cl-light-gray" :class="iconMap(option)" />
        <input
          class="m0"
          type="radio"
          :value="option"
          name="shipping-method"
          v-model="column"
          @change="changeColumn"
        >
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      column: 'on',
      productsColumns: ['off', 'on']
    }
  },
  computed: {
  },
  methods: {
    iconMap (option) {
      return option === 'on'
        ? 'icon-kafelki'
        : 'icon-kafelki-2'
    },
    changeColumn () {
      this.$emit('change-column', this.column)
    }
  }
}
</script>
<style lang="scss" scoped>
    @import '~theme/css/base/text';
    @import '~theme/css/variables/colors';
    @import '~theme/css/helpers/functions/color';
    $color-tertiary: color(tertiary);
    .columns {
      display: flex;
      label {
        @media (min-width: 768px) {
          display: flex !important;
        }
      }
      input {
        display: none;
      }
    }
    @media (max-width: 770px) {
      .columns {
        width: 100%;
      }
    }
</style>
