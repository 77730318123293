<template>
  <div class="container pb60 top-second-container">
    <div class="row mb15 ">
      <div class="col-md-12">
        <breadcrumbs />
        <h1 class="h2 mt15 mb15 secondary-font break-word hidden-xs">
          {{ getCurrentCategory.name }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="row m0 col-xs-12 card-container-outer">
        <div class="flex col-xs-6 col-md-4 col-lg-3 center-xs card-container" @mouseenter="productCount(category, index)" @mouseleave="resetCount()" v-for="(category, index) in categoriesMap[getCurrentCategory.id]" :key="category.id">
          <router-link class="card-hover relative flex" :to="categoryLink(category)">
            <img class="bg-cl-white px10 py5 card-image brdr-10px" :src="category.cate_landing_img ? getLandingImg(category.cate_landing_img) : '/assets/placeholder.svg'" :alt="category.name">
            <div class="middle" v-if="index === currentIndex">
              <div class="card-text">
                {{ $t('Products') }}: {{ productTotal }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div id="category-desc" class="col-sm-12 category-desc weight-400 cl-black lh25">
        <div v-html="getCurrentCategory.description" class="category-inner" :class="{ 'expanded' : expanded }" />
        <span class="show-more" @click="expandDesc" v-if="!expanded && getCurrentCategory.description">{{ $t('Expand') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import groupBy from 'lodash-es/groupBy'
import { getThumbnailCategoryPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue'
import { quickSearchByQueryProductCount } from 'theme/helpers/elastic'
import { buildFilterProductsQuery } from '@vue-storefront/core/helpers'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      productTotal: 0,
      expanded: false,
      currentIndex: null
    }
  },
  beforeMount () {
    this.$store.dispatch('category-next/fetchMenuCategories', { parent: this.getCurrentCategory.id })
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    }
  },
  methods: {
    expandDesc () {
      this.expanded = true
    },
    resetCount () {
      this.productTotal = 0
    },
    async productCount (category, index) {
      this.currentIndex = index
      let response = await quickSearchByQueryProductCount({
        query: buildFilterProductsQuery(category, []),
        size: 0
      })
      this.productTotal = response.total && response.total.value
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    getLandingImg (image) {
      return this.getThumbnail(image, 200, 200, 'category')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);
$white: color(white);

.card-hover {
  @media (hover: hover) {
    &:hover .card-image {
      opacity: 0.5;
    }
    &:hover .middle {
      opacity: 1;
    }
    &:hover .card-text {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  .card-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    min-width: 120px;
    min-height: 120px;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 70%;
      max-height: 70%;
    }
  }
  .middle {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
  .card-text {
    display: inline-block;
    white-space: nowrap;
    background-color: $main;
    color: $white;
    opacity: 0;
    visibility: hidden;
    font-size: 16px;
    line-height: 1.5;
    padding: 9px 20px;
    margin-top: 10px;
    transform: translateY(20px);
    transition: all .2s;
  }
}
.card-container {
  padding-bottom: 40px;
  @media (max-width: 767px) {
    padding-bottom: 25px;
  }
}
.card-container-outer {
  @media (max-width: 767px) {
    padding-top: 5px;
  }
}
.category-inner{
  max-height: 7em;
  overflow: hidden
}
.expanded{
  max-height: initial !important
}
span.show-more {
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 15px;
}
</style>
