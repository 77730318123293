<template>
  <div class="w-100 py5">
    <span
      class="flex middle-xs"
      @click="$emit('change', variant)"
      :aria-label="variant.label"
      :class="[{'active': isActive, 'rounded-border': !multiply}, !multiply ? 'filter-label' : 'filter-label-squere']"
    >
      {{ variant.label }}
    </span>
  </div>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  props: {
    multiply: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mixins: [filterMixin]
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.rounded-border {
  &::before {
    border-radius: 50%;
  }
}
.filter-label-squere {
  cursor: pointer;
  position: relative;
  &:hover {
   &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
  &::before {
    content: "";
    position: relative;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
}
.filter-label {
  cursor: pointer;
  position: relative;
  &:hover {
   &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      // top: 8px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
    }
  }
  &::before {
    content: "";
    position: relative;
    // top: 1px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      // top: 8px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
      @media (max-width: 767px) {
        top: 7px;
      }
    }
  }
}
</style>
