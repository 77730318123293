<template>
  <div class="visible-xs-and-tablet">
    <button
      class="brdr-none cl-main bg-cl-white h5 open-filters-btn"
      @click="openFilters"
    >
      <i class="icon-filtry pr5" />{{ $t('Filters') }}
    </button>
    <transition name="slide-right">
      <div class="mobile-filters bg-cl-main-smoke" v-if="mobileFilters">
        <div class="mobile-filters-header bg-cl-white flex middle-xs py15">
          <i class="fs26 icon-Return cl-main pl10 pr15" @click="closeFilters" />
          <p class="m0 secondary-font">
            {{ $t('Filters and sort') }}
          </p>
        </div>
        <div class="sidebar">
          <div class="basic-box active">
            <p class="fs-medium m0 py20 px15 weight-600">
              {{ $t('Sorting and category') }}
            </p>
            <div class="mx15 mb5">
              <p class="m0 h6 weight-600 cl-black">
                {{ $t('You are in') }}:
              </p>
              <div class="flex between-xs">
                <p class="m0 cl-main h5 py5">
                  {{ getCurrentCategory.name }}
                </p>
              </div>
            </div>
            <div v-if="getCurrentCategory.id !== 2 && getCustomParentCategory">
              <p class="m0 px15 h6 weight-600 cl-black">
                {{ $t('Back to') }}:
              </p>
              <router-link
                class="py10 m0 px15 brdr-bottom-1 brdr-cl-secondary h5 flex between-xs cl-main pointer"
                @click.native="clickCategory(getCurrentCategory.id)"
                :to="localizedRoute({ path: '/' + getCustomParentCategory.url_path, query: $route.query})"
              >
                {{ getCustomParentCategory.name }}
              </router-link>
            </div>
            <div>
              <div class="category-container" :class="toggleFilters.has('category') ? 'opne' : 'close'">
                <div
                  :key="category.slug"
                  v-for="category in categoriesMap[getCurrentCategory.id]"
                >
                  <router-link
                    @click.native="clickCategory(category.id)"
                    class="py15 pl30 pr20 m0 brdr-bottom-1 brdr-cl-secondary cl-black h5 flex between-xs pointer"
                    :class="{ 'active': getCurrentCategory.id === category.id }"
                    :to="localizedRoute({path: '/' + category.url_path, query: $route.query})"
                  >
                    <span>{{ category.name }}</span>
                  </router-link>
                </div>
              </div>
              <div
                @click="toggleCurrent('category')"
                class="py5 flex center-xs middle-xs pointer h5 cl-main"
                v-show="categoriesMap[getCurrentCategory.id] && categoriesMap[getCurrentCategory.id].length > 6"
              >
                <span>{{ toggleFilters.has('category') ? 'Ukryj' : $t('View all') }}</span>
                <i class="h3 cl-main" :class="toggleFilters.has('category') ? 'icon-rozwi top' : 'icon-rozwi'" />
              </div>
            </div>
            <div class="m15">
              <p class="m0 h6 weight-600 cl-black">
                {{ $t('Sort by') }}:
              </p>
              <div @click="toggleCurrent('sort')" class="flex between-xs">
                <p
                  class="m0 cl-main h5 py5"
                >
                  {{ $t(sortby.label) }}
                </p>
                <i class="h3 cl-black" :class="toggleFilters.has('sort') ? 'icon-rozwi top': 'icon-rozwi'" />
              </div>
              <div
                v-show="toggleFilters.has('sort')"
                v-for="variant in sortingVariants"
                :key="variant.id + 'sort'"
                class="my10"
              >
                <span
                  class="filter-label rounded-border"
                  @click="$emit('changeFilter', variant), changeOrder(variant)"
                  :class="{ 'active': sortby.label === variant.label && isSortInQuery}"
                >
                  {{ $t(variant.label) }}
                </span>
              </div>
            </div>
            <div class="p15 brdr-top-1 brdr-bottom-1 brdr-cl-matterhorn flex between-xs middle-xs">
              <span class="fs-medium weight-500">{{ $t('Filters') }}</span>
              <span class="fs-medium-small m0 weight-500 cl-burnt-sienna" @click="resetAllFilters">{{ $t('Clear filters') }}</span>
            </div>
            <active-filters class="px15 pb10 bg-cl-white brdr-bottom-1 brdr-cl-matterhorn wrap" :styles-sidebar-mobile="true" :show-label="true" :filters="getCurrentFilters" @change="$emit('changeFilter', $event)" />
            <div class="iphone-save px15 py10 bg-cl-white brdr-bottom-1 brdr-cl-matterhorn">
              <div class="py5">
                <span
                  class="filter-label-squere flex bottom-xs"
                  @click="toggleSaleFilter()"
                  :class="{ 'active': isSaleFilterActive }"
                >
                  {{ $t('Sale') }}
                </span>
              </div>
              <div class="py5">
                <span
                  class="filter-label-squere flex bottom-xs"
                  @click="toggleNewFilter()"
                  :class="{ 'active': isNewFilterActive }"
                >
                  {{ $t('News') }}
                </span>
              </div>
              <div class="py5">
                <span
                  class="filter-label-squere flex bottom-xs"
                  @click="toggleBestselleryFilter()"
                  :class="{ 'active': isBestselleryFilterActive }"
                >
                  {{ $t('Bestsellers') }}
                </span>
              </div>
              <div
                v-for="(filter, filterIndex) in availableFilters"
                :key="filterIndex + 'main-filter'"
              >
                <p v-if="!hidePriceFilter" class="py5 my0 h5 relative flex pointer cl-black weight-600 middle-xs between-xs filter-title"
                   :class="{'active' : toggleFilters.has(filterIndex)}" @click="toggleCurrent(filterIndex)"
                >
                  {{ $t(filterIndex + '_filter') }}
                  <i class="h3 cl-black" :class="toggleFilters.has(filterIndex) ? 'icon-rozwi top cl-main' : 'icon-rozwi'" />
                </p>
                <div v-if="filterIndex === 'color_aggregated'">
                  <color-selector
                    v-show="toggleFilters.has(filterIndex)"
                    context="category"
                    code="color"
                    v-for="(color, index) in filter"
                    :key="index + filterIndex + 'color'"
                    :variant="color"
                    :selected-filters="getCurrentFilters"
                    @change="$emit('changeFilter', $event)"
                  />
                </div>
                <div v-else-if="!hidePriceFilter" class="flex wrap generic-selector">
                  <generic-selector
                    v-show="toggleFilters.has(filterIndex)"
                    context="category"
                    :code="filterIndex"
                    :multiply="filterIndex !== 'price'"
                    v-for="option in filter"
                    :key="option.id + filterIndex"
                    :class="[filter.length > 5 ? 'generic-element' : 'w-100']"
                    :variant="option"
                    :selected-filters="getCurrentFilters"
                    @change="$emit('changeFilter', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-nav env-padding-bottom">
            <p class="close uppercase weight-200 my10 fs15" @click="closeFilters">
              {{ $t("Apply") }}<span class="pl5">({{ getCategoryProductsTotal }})</span>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ColorSelector from 'theme/components/core/ColorSelector'
import GenericSelector from 'theme/components/core/GenericSelector'
import pickBy from 'lodash-es/pickBy'
import ActiveFilters from './ActiveFilters'
import config from 'config'
import SortBy from '@vue-storefront/core/compatibility/components/SortBy'
import groupBy from 'lodash-es/groupBy'

export default {
  components: {
    ColorSelector,
    GenericSelector,
    ActiveFilters
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  mixins: [SortBy],
  data () {
    return {
      mobileFilters: false,
      toggleFilters: new Set(['price']),
      sortby: null
    }
  },
  computed: {
    ...mapGetters({
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getCustomParentCategory: 'category-next/getCustomParentCategory',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentFilters: 'category-next/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    hidePriceFilter () {
      let arr = config.server.categoryIdsToHidePrice
      return arr.includes(this.getCurrentCategory.id)
    },
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    availableFilters () {
      return pickBy(this.filters, (filter, filterType) => { return (filter.length && !this.getSystemFilterNames.includes(filterType)) })
    },
    isBestselleryFilterActive () {
      return this.$store.state.ui.isBestselleryFilterActive
    },
    isSaleFilterActive () {
      return this.$store.state.ui.isSaleFilterActive
    },
    isNewFilterActive () {
      return this.$store.state.ui.isNewFilterActive
    },
    isSortInQuery () {
      return !!this.$route.query['sort']
    }
  },
  mounted () {
    this.toggleFilters = new Set(['price'])
  },
  watch: {
    value: {
      handler () {
        const defaultVariant = this.value && this.value.length ? this.value : config.products.defaultSortBy.attribute
        this.sortby = this.sortingVariants.find(variant => variant.id.includes(defaultVariant))
      },
      immediate: true
    }
  },
  methods: {
    toggleBestselleryFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleBestselleryFilter').then(() => {
        if (!this.isBestselleryFilterActive) {
          delete query.bestsellery
          this.$router.push({ query })
        } else {
          query.bestsellery = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleSaleFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleSaleFilter').then(() => {
        if (!this.isSaleFilterActive) {
          delete query.sale
          this.$router.push({ query })
        } else {
          query.sale = 'true'
          this.$router.push({ query })
        }
      })
    },
    toggleNewFilter () {
      let query = Object.assign({}, this.$route.query)
      this.$store.dispatch('ui/toggleNewFilter').then(() => {
        if (!this.isNewFilterActive) {
          delete query.new
          delete query.sort
          this.$router.push({ query })
        } else {
          query.new = 'true'
          query.sort = 'id:desc'
          this.$router.push({ query })
        }
      })
    },
    clickCategory (id) {
      this.$store.dispatch('category-next/fetchMenuCategories', { parent: id })
    },
    changeOrder (variant) {
      this.sortby = variant
    },
    closeFilters () {
      this.$store.dispatch('ui/toogleMobileFilter', false)
      this.mobileFilters = false;
      let body = document.getElementsByTagName('body')[0]
      body.classList.remove('iphone-scroll')
    },
    openFilters () {
      this.$store.dispatch('category-next/fetchMenuCategories', { parent: this.getCurrentCategory.id })
      this.$store.dispatch('ui/toogleMobileFilter', true)
      this.mobileFilters = true;
      let body = document.getElementsByTagName('body')[0]
      body.classList.add('iphone-scroll')
    },
    resetAllFilters () {
      this.toggleFilters.clear()
      this.$store.dispatch('ui/resetSaleFilter')
      this.$store.dispatch('ui/resetNewFilter')
      this.$store.dispatch('ui/resetQueryFilter')
      this.$store.dispatch('category-next/resetSearchFilters')
    },
    toggleCurrent (filterIndex) {
      if (filterIndex) {
        let cache = this.toggleFilters
        if (!cache.has(filterIndex)) {
          cache.add(filterIndex)
        } else {
          cache.delete(filterIndex)
        }
        this.toggleFilters = null
        this.toggleFilters = cache
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.category-container {
  overflow: auto;
  overflow-y: hidden;
}
.open {
  max-height: unset;
}
.close {
  max-height: 282px;
}
.iphone-save {
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
    margin-bottom: 150px;
  }
}
.generic-selector {
  .generic-element {
    min-width: 70px;
  }
}
.mobile-filters {
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 120px;
  .fs26 {
    font-size: 26px;
  }
  .mobile-filters-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
  }
}
.open-filters-btn {
  position: absolute;
  bottom: 15px;
}
.top {
  transform: rotate(180deg);
}
.right {
  transform: rotate(-90deg);
}
.basic-box {
  height: 100%;
  overflow: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  transform: translateX(100%);
  transition: all 0.2s;
  opacity: 0;
  &.active {
    opacity: 1;
    transform: none;
  }
}
.sidebar {
  display: flex;
  overflow: hidden;
  padding-top: 50px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.category-label-checkbox {
  position: relative;
  &::before {
    content: "";
    position: relative;
    top: 1px;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #707070;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 10px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
    }
  }
}
.filter-title.active {
  color: #1396EC !important;
  position: relative;
}
.rounded-border {
  &::before {
    border-radius: 50%;
  }
}
.filter-label-squere {
  cursor: pointer;
  position: relative;
  &:hover {
   &::before {
      border: 2px solid $main;
    }
    &:after {
      left: 5px;
      top: 6px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
  &::before {
    content: "";
    position: relative;
    top: 1px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &:after {
    content: "";
    position: absolute;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 6px;
      background: $main;
      width: 12px;
      height: 12px;
    }
  }
}
.filter-label {
  position: relative;
  &::before {
    content: "";
    position: relative;
    top: 2px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 2px solid #e0e0e0;
  }
  &.active {
    &::before {
      border: 2px solid $main;
    }
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 10px;
      border-radius: 50%;
      background: $main;
      width: 8px;
      height: 8px;
    }
  }
}
.bottom-nav {
  background-color: white;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  justify-content: center;
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .close {
    width: 80%;
    border: 1px solid $main;
    background-color: $main;
    padding: 10px 0px;
    text-align: center;
    color: white;
    border-radius: 26px;
  }
}

// dropdown animation
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.5s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
