<template>
  <button
    class="
      p0 bg-cl-primary brdr-1 brdr-cl-primary mr5 mb5
      brdr-square h5 cl-tertiary size-selector
    "
    :class="{ active: isActive }"
    @click="$emit('change', variant)"
    :aria-label="'Select size ' + variant.label"
  >
    {{ variant.label }}
  </button>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin]
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(main);
  $color-disabled: color(secondary, $colors-border);

  .size-selector {
    width: 35px;
    height: 35px;

    &:hover {
      border-color: $color-active;
      color: $color-active;
      border-width: 2px;
    }

    &.active {
      border-color: $color-active;
      border-width: 2px;
      color: $color-active;
    }

    &:disabled {
      border-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;

      &:hover,
      &:after {
        border-width: 1px;
      }
    }
  }
</style>
